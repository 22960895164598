<script setup lang="ts">
import { DateHelper } from '~/helpers/Date';
import type { Schemas } from '#shopware';
import type { AhRealtimeStockShipping } from '~/types/models/cart';

export type DeliveryStateType = 'normal' | 'badge' | 'text';

const props = withDefaults(
    defineProps<{
        mode?: DeliveryStateType;
        colorText?: boolean;
        availability?: Schemas['AhProductAvailability'][] | undefined;
        realtimeAvailability?: AhRealtimeStockShipping | undefined;
    }>(),
    {
        mode: 'normal',
        colorText: true,
        availability: undefined,
        realtimeAvailability: undefined,
    },
);

const slots = useSlots();
const hasSlotContent = computed(() => !!(slots?.default && typeof slots?.default === 'function'));

const { isLoggedIn } = useUser();

const { availability, realtimeAvailability } = toRefs(props);

const availabilityData = computed<{ status: number | undefined; shippingDate: string | undefined } | undefined>(() => {
    if (realtimeAvailability.value) {
        return {
            status: realtimeAvailability.value.status,
            shippingDate: realtimeAvailability.value.shippingdate,
        };
    }

    if (Array.isArray(availability.value) && availability.value.length) {
        const relevantAvailability = availability.value[0];

        return {
            status: relevantAvailability.status,
            shippingDate: relevantAvailability.availabilityDate,
        };
    }

    return undefined;
});

enum AvailabilityStatus {
    AvailableNow = 1,
    AvailableShortly = 2,
    AvailableAtDate = 3,
    AvailableAtDateIfOrderedNow = 4,
    Archived = 5,
    AvailableLater = 6, // checkout special state
}

const availabilitySnippet = computed(() => {
    switch (availabilityData.value?.status) {
        case AvailabilityStatus.AvailableNow:
            return 'productShippingStateOne';
        case AvailabilityStatus.AvailableShortly:
            return 'productShippingStateTwo';
        case AvailabilityStatus.AvailableAtDate:
            return 'productShippingStateThree';
        case AvailabilityStatus.AvailableAtDateIfOrderedNow:
            return 'productShippingStateFour';
        case AvailabilityStatus.Archived:
            return 'productShippingStateFive';
        case AvailabilityStatus.AvailableLater:
            return 'availableLater';
        default:
            return 'productShippingStateNotAvailable';
    }
});

const shippingStateMap = [
    { snippet: 'productShippingStateOne', color: 'brand-success', options: {} },
    {
        snippet: 'productShippingStateTwo',
        color: 'bg-gradient-to-br from-brand-success to-brand-warning from-50% to-50%',
        colors: ['brand-success', 'brand-warning'],
        options: {},
    },
    {
        snippet: 'productShippingStateThree',
        color: 'brand-warning',
        options: {
            shippingDate: DateHelper.getFormatted(availabilityData.value?.shippingDate),
        },
    },
    {
        snippet: 'productShippingStateFour',
        color: 'brand-warning',
        options: {
            shippingDate: DateHelper.getFormatted(availabilityData.value?.shippingDate),
        },
    },
    { snippet: 'productShippingStateFive', color: 'brand-danger', options: {} },
    { snippet: 'productShippingStateNotAvailable', color: 'brand-danger', options: {} },
    { snippet: 'availableLater', color: 'brand-warning', options: {} },
];

const availabilityConfig = computed(
    () => shippingStateMap.find((state) => state.snippet === availabilitySnippet.value)!,
);

/**
 * don't remove; enforce unocss to include dynamically used classes in storybook:
 * text-brand-success text-brand-warning
 * bg-brand-success bg-brand-warning bg-brand-danger
 */
</script>

<template>
    <div
        v-if="isLoggedIn"
        class="inline-flex text-sm items-center"
    >
        <div
            class="flex"
            :class="{ 'mr-2.5': hasSlotContent }"
        >
            <div
                v-if="availabilityData?.status === 2 && mode == 'normal'"
                class="relative rounded-full h-3 w-3 top-0.5 pl-3 sm:pl-0"
                :class="`bg-${shippingStateMap[0].color}`"
            />

            <div
                v-if="mode !== 'text'"
                class="rounded-full relative h-3 w-3 shrink-0 top-0.5"
                :class="[
                    availabilityData?.status === 2 && mode !== 'badge' ? '-ml-1' : '',
                    availabilityData?.status === 2 && mode === 'badge' ? availabilityConfig.color : '',
                    mode !== 'badge' && availabilityData?.status === 2 && availabilityConfig.colors
                        ? `bg-${availabilityConfig.colors[1]}`
                        : `bg-${availabilityConfig.color}`,
                ]"
            />

            <div
                v-if="mode !== 'badge'"
                :class="[
                    colorText
                        ? availabilityData?.status === 2 && availabilityConfig.colors
                            ? `text-${availabilityConfig.colors[1]}`
                            : `text-${availabilityConfig.color}`
                        : '',
                    mode !== 'text' ? 'ml-2.5 hyphens-auto' : '',
                ]"
            >
                <slot>{{ $t(availabilityConfig.snippet, availabilityConfig.options) }}</slot>
            </div>
        </div>
    </div>
</template>
